import webService from "@/services/webService";
import { getFilterQueryStringWithoutArray, Role } from "@/helpers/helper.js";
const getDefaultState = () => {
  return {};
};

const state = getDefaultState();

const getters = {};

const mutations = {};
const actions = {
  userExistInCampaign: ({ state, commit, getters, dispatch }, data) => {
    return webService
      .checkUserExistInCampaign(data)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        const message = "Error while check user exist";
        dispatch("showToast", {
          message,
          class: "text-white bg-danger",
        });
        return err;
      });
  },
  campaignRegistration: ({ state, commit, getters, dispatch }, data) => {
    return webService
      .campaignRegistration(data)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        const message = "Error while creating campaign registration";
        dispatch("showToast", {
          message,
          class: "text-white bg-danger",
        });
        return err;
      });
  },
  campaignRegisterLater: ({ state, commit, getters, dispatch }, data) => {
    return webService
      .campaignRegisterLater(data)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        const message = "Error while creating campaign register later";
        dispatch("showToast", {
          message,
          class: "text-white bg-danger",
        });
        return err;
      });
  },
  checkCandidateExistInCampaignPreReg: ({}, email) => {
    return webService
      .checkCandidateExistInCampaignPreReg(email)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        console.error(
          "Error while check candidate exist in campaign pre registration: ",
          err
        );
      });
  },
};

export default { state, getters, mutations, actions };
